<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-button
            @click="onDelete"
            v-if="$getPermission($route.path + '/delete')"
            type="danger"
          >
            删除来信</a-button
          >
        </a-space>
      </template>
    </Pane>

    <div class="container">
      <div class="header">
        <div class="title">
          {{ detail.title }}
        </div>
        <div class="extra">
          <a-badge
            v-if="detail.status"
            :status="getStatus(detail.status)"
            :text="getStatusName(detail.status)"
          />
        </div>
      </div>

      <div class="content" v-html="getContent(detail.content)"></div>

      <div class="meta">
        <a-space>
          <!-- <a-tooltip>
            <template slot="title"> 来信人员 </template>
            <div>
              <a-icon type="user" class="icon" />
              <span class="value">{{ detail.source }}</span>
            </div>
          </a-tooltip> -->

          <a-tooltip>
            <template slot="title"> 来信时间 </template>
            <div>
              <a-icon type="clock-circle" class="icon" />
              <span class="value">{{ detail.createAt }}</span>
            </div>
          </a-tooltip>
        </a-space>
      </div>

      <a-divider />

      <div v-if="Array.isArray(detail.subList) && detail.subList.length > 0">
        <a-steps direction="vertical">
          <a-step
            status="finish"
            v-for="(item, index) in detail.subList"
            :key="index"
            :description="item.content"
          >
            <div slot="icon">
              <DefaultAvatar :name="item.createBy" />
            </div>
            <div slot="title">
              <a-space>
                <span>{{ item.type }}</span>

                <a-tooltip>
                  <template slot="title"> 回复时间 </template>
                  <div class="meta">
                    <a-icon type="clock-circle" class="icon" />
                    <span class="value">{{ item.createAt }}</span>
                  </div>
                </a-tooltip>

                <a-tooltip
                  v-if="
                    $getPermission($route.path + '/delete') &&
                    detail.status !== 'complete'
                  "
                >
                  <template slot="title"> 删除 </template>
                  <div>
                    <a-icon
                      type="delete"
                      class="remove"
                      @click="removeItem(index)"
                    />
                  </div>
                </a-tooltip>
              </a-space>
            </div>
          </a-step>
        </a-steps>

        <a-divider
          v-if="
            $getPermission($route.path + '/delete') &&
            detail.status !== 'complete'
          "
        />
      </div>

      <div
        v-if="
          $getPermission($route.path + '/delete') &&
          detail.status !== 'complete'
        "
      >
        <a-form :form="form" :colon="false" @submit="handleSubmit">
          <a-form-item label="回复">
            <a-textarea
              v-decorator="[
                'reply',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>

          <a-form-item label="处理方式">
            <a-radio-group
              v-decorator="[
                'type',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-radio
                v-for="item in [
                  {
                    name: '问题回复',
                  },
                  {
                    name: '确认完成',
                  },
                ]"
                :key="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <div class="center">
            <a-space>
              <a-button @click="$router.go(-1)">关闭</a-button>
              <a-button type="primary" html-type="submit" :loading="saveLoading"
                >保存</a-button
              >
            </a-space>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultAvatar from "@/components/default-avatar";
import { getStatus, getStatusName } from "./data";
import request from "@/api/request";
import moment from "moment";
import { mapState } from "vuex";

function remove(data) {
  return request({
    url: "/common-service/publicLetters/delete",
    method: "post",
    data,
  });
}

function fetchDetail(params) {
  return request({
    url: "/common-service/publicLetters/query",
    params,
  });
}

function update(data) {
  return request({
    url: "/common-service/publicLetters/update",
    method: "post",
    data,
  });
}

export default {
  components: {
    DefaultAvatar,
  },

  data() {
    return {
      id: "",
      detail: {},

      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.id = id;
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      const hide = this.$message.loading("加载中...");
      fetchDetail({
        id: this.id,
      })
        .then((res) => {
          this.detail = res || {};
        })
        .finally(() => {
          hide();
        });
    },
    getStatus,
    getStatusName,

    getContent(content) {
      return content ? content.replace(/\n/g, "<br>") : "";
    },

    onDelete() {
      const that = this;
      this.$confirm({
        title: "是否删除？",
        onOk() {
          const hide = that.$message.loading("删除中...");
          remove({
            id: that.id,
          })
            .then(() => {
              that.$close(that.$route.path);
            })
            .finally(() => {
              hide();
            });
        },
      });
    },

    removeItem(index) {
      const list = [...this.detail.subList];
      list.splice(index, 1);
      update({
        ...this.detail,
        subList: list,
      }).then(() => {
        this.getDetail();
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let status = "";

          if (values.type === "问题回复") {
            status = "ongoing";
          } else if (values.type === "确认完成") {
            status = "complete";
          }

          this.saveLoading = true;

          update({
            ...this.detail,
            status,

            subList:
              Array.isArray(this.detail.subList) && values.reply
                ? [
                    ...this.detail.subList,
                    {
                      content: values.reply,
                      type: values.type,
                      createBy: this.user.userName,
                      createAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                    },
                  ]
                : this.detail.subList,
          })
            .then(() => {
              this.getDetail();
              this.form.resetFields();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 48px 160px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .title {
    font-weight: bold;
    font-size: 20px;
  }

  .extra {
    margin-left: auto;
  }
}

.content {
  margin-bottom: 12px;
  color: #999;
}

.meta {
  color: #999;
  font-size: 12px;
  .value {
    margin-left: 4px;
  }
}

.remove {
  color: #999;
  cursor: pointer;
  font-size: 12px;
}

.center {
  margin-top: 80px;
}
</style>
