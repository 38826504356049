var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('a-space',{staticStyle:{"margin-top":"12px","margin-right":"-16px"}},[(_vm.$getPermission(_vm.$route.path + '/delete'))?_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.onDelete}},[_vm._v(" 删除来信")]):_vm._e()],1)]},proxy:true}])}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.detail.title)+" ")]),_c('div',{staticClass:"extra"},[(_vm.detail.status)?_c('a-badge',{attrs:{"status":_vm.getStatus(_vm.detail.status),"text":_vm.getStatusName(_vm.detail.status)}}):_vm._e()],1)]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.getContent(_vm.detail.content))}}),_c('div',{staticClass:"meta"},[_c('a-space',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 来信时间 ")]),_c('div',[_c('a-icon',{staticClass:"icon",attrs:{"type":"clock-circle"}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.detail.createAt))])],1)],2)],1)],1),_c('a-divider'),(Array.isArray(_vm.detail.subList) && _vm.detail.subList.length > 0)?_c('div',[_c('a-steps',{attrs:{"direction":"vertical"}},_vm._l((_vm.detail.subList),function(item,index){return _c('a-step',{key:index,attrs:{"status":"finish","description":item.content}},[_c('div',{attrs:{"slot":"icon"},slot:"icon"},[_c('DefaultAvatar',{attrs:{"name":item.createBy}})],1),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('a-space',[_c('span',[_vm._v(_vm._s(item.type))]),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 回复时间 ")]),_c('div',{staticClass:"meta"},[_c('a-icon',{staticClass:"icon",attrs:{"type":"clock-circle"}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.createAt))])],1)],2),(
                  _vm.$getPermission(_vm.$route.path + '/delete') &&
                  _vm.detail.status !== 'complete'
                )?_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 删除 ")]),_c('div',[_c('a-icon',{staticClass:"remove",attrs:{"type":"delete"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],2):_vm._e()],1)],1)])}),1),(
          _vm.$getPermission(_vm.$route.path + '/delete') &&
          _vm.detail.status !== 'complete'
        )?_c('a-divider'):_vm._e()],1):_vm._e(),(
        _vm.$getPermission(_vm.$route.path + '/delete') &&
        _vm.detail.status !== 'complete'
      )?_c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"回复"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'reply',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'reply',\n              {\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"处理方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'type',
              {
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'type',\n              {\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l(([
                {
                  name: '问题回复',
                },
                {
                  name: '确认完成',
                },
              ]),function(item){return _c('a-radio',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }